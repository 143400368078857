<template>
  <div>
    <platformHead
      :openStatusPop="openStatusPop"
      :closeWebview="closeWebview"
      :filterDate="filterDate"
      :changeTab="changeTab"
      :orderTabs="orderTabs"
      :StatByStatus="StatByStatus"
      :tabBar="tabBar"
    ></platformHead>
    <ScrollBox class="opOrderListScroll" @on-top="scrollTop" @on-bottom="scrollBottom" :loadType="loadType">
      <template v-slot:scrollBox>
        <div class="contentBox">
          <div class="platformTips" v-if="['pfizer'].includes($cookies.get('tenant'))">
            <div class="platformTips_top">注意事项</div>
            <div class="platformTips_con">
              网约车上座率要求（5人座车型上座率>=1人, 别克车型上座率>=3人, 其他车型上座率>=60%上座率）
              <span v-show="!isShowTips">...</span>
              <svg class="icon" aria-hidden="true" @click="isShowTips = !isShowTips" :class="isShowTips ? 'rotateDown' : ''">
                <use xlink:href="#icon-jiantouxia" />
              </svg>
              <div v-show="isShowTips" class="platformTips_con">
                复制订单或设置返程可在订单详情操作，预约成功后、订单将显示司机信息，订单详情中查看
              </div>
            </div>
          </div>
          <div class="platformTips" v-if="['dsm'].includes($cookies.get('tenant'))">
            <div class="platformTips_top">注意事项</div>
            <div class="platformTips_con">
              复制订单或设置返程可在订单详情操作，预约成功后、订单将显示司机信息，订单详情中查看
            </div>
          </div>
          <ul>
            <li v-for="(item, index) in dataList" :key="index" @click="viewOrder(item, index)" :class="[flag == index && 'choiseOp', backListOn == index && 'opHomeOn']" :id="'opOrderList' + index">
              <a :id="'listHref' + index" :href="'#opOrderList' + index" style="display: none" @click="stopSt"></a>
              <orderListPart :item="item" :showCallBox="showCallBox" :isloding="isloding" :dropDown="dropDown" :btnClick="btnClick" :index="index" :tabBar="tabBar"></orderListPart>
              <!-- <platformListpart
                :item="item"
                :isloding="isloding"
                :btnClick="btnClick"
                :dropDown="dropDown"
                :index="index"
                :tabBar="tabBar"
              ></platformListpart> -->
            </li>
          </ul>
        </div>
        <Skeleton v-if="isFirst" :skList="skList" />
        <NoData v-if="!dataList.length && loadType == '' && !isFirst"></NoData>
      </template>
    </ScrollBox>
    <AddButton v-if="!this.SmartStorage.get('viewOnly') && checkCanNewItem.show" :color="$publicFun.setColor()" @click="checkItem(true)"></AddButton>
    <transition name="slide-fade">
      <callBox v-if="isShowCallBox" :choiseItem="choiseItem" :showCallBox="showCallBox"></callBox>
    </transition>
    <cancelResonPop ref="cancelResonPop" :canselect="canselect" :cancelReson="cancelReson"></cancelResonPop>
    <MessageBox ref="tipsMsg" type="tips" :messageTxt="messageTxt" @on-ok="MessageBtn()"></MessageBox>
    <Map ref="mapPop" @close="refrashData" />
    <statusPop ref="statusPop" :openStatusPop="openStatusPop" :closeWebview="closeWebview" :changeTab="changeTab" :orderTabs="orderTabs" :StatByStatus="StatByStatus" :tabBar="tabBar"></statusPop>
    <projectGrade ref="projectGrade" :refrashData="refrashData"></projectGrade>
    <returnBox
      :retype="'carRemark'"
      @closeDialog="isCarRemarkDialog = false"
      @quotePiece="SaveUseCarRemark"
      :isloding="isloding"
      :showBtn="showBtn"
      :carRemark="clickItem.itsItemData.useCarRemark"
      v-if="isCarRemarkDialog"
    ></returnBox>
    <browserLoader :iframeUrl="iframeUrl" v-if="showDiDiH5" :h5GoClose="h5GoClose"></browserLoader>
  </div>
</template>
<script>
import browserLoader from '@/views/common/platform/browserLoader';
import orderListPart from '@/views/common/msd/orderListPart';
import returnBox from '@/views/common/returnBox';
import choiseExpenseItem from '@/views/controllers/choiseExpenseItem.js';
import filterEmoji from '@/views/controllers/filterEmoji.js';
import statusPop from '@/views/common/platform/statusPop';
import Map from '@/views/common/map/map';
import platformHead from '@/views/common/platform/platformHead';
import callBox from '@/views/common/msd/callBox';
import cancelResonPop from '@/views/common/platform/cancelResonPop';
import projectGrade from '@/views/common/projectGrade/projectGrade';
import submitOrder from '@/views/controllers/submitOrder.js';
export default {
  mixins: [submitOrder, filterEmoji, choiseExpenseItem],
  data() {
    return {
      iframeUrl: '',
      showDiDiH5: false,
      isShowCallBox: false,
      isCarRemarkDialog: false,
      chioseIndex: 0,
      flag: -1,
      showBtn: true,
      dropDown: false,
      isloding: true,
      total: 0,
      loadType: '',
      isShowTips: false,
      skList: [
        {
          width: '65%',
          row: 5
        },
        {
          width: '20%',
          position: 'right'
        },
        {
          width: '40%',
          inline: true
        },
        {
          width: '50%'
        },
        {
          width: '30%',
          ml: '.1rem'
        },
        {
          width: '15%',
          inline: true
        },
        {
          width: '100%',
          inline: true
        },
        {
          width: '0',
          inline: true
        }
      ],
      filterDate: {},
      searchDate: [],
      isFirst: true,
      orderTabs: [],
      clickItem: {},
      reason: '',
      otherReason: '',
      cancelReson: [],
      canselect: true,
      messageType: '',
      messageTxt: '',
      checkCanNewItem: {},
      pageIndex: 1,
      pageSize: 10,
      dataList: [],
      tabBar: {}
    };
  },
  async created() {
    this.redirectRouteListeners(); //记录全局路由轨迹
    this.$root.$eventHub.$on('closeWebview', data => {
      this.closeWebview();
    });
    let tabBar = this.SmartStorage.get('orderlistStatu');
    if (tabBar) {
      this.tabBar = tabBar;
    }
    !this.SmartStorage.get('viewOnly') && this.checkItem();
    this.installSingler();
    this.StatByStatus([]);
  },
  components: {
    Map,
    orderListPart,
    cancelResonPop,
    statusPop,
    platformHead,
    projectGrade,
    returnBox,
    callBox,
    browserLoader
  },
  beforeDestroy() {
    this.dataList = null;
    this.$root.$eventHub.$off('closeWebview');
    this.refreshHub.uninstall();
  },
  methods: {
    showCallBox(item) {
      console.log(item);
      this.choiseItem = item;
      this.isShowCallBox = !this.isShowCallBox;
    },
    h5GoClose() {
      this.showDiDiH5 = false;
      this.StatByStatus(this.searchDate);
    },
    /**
     * 打开评分页面
     */
    goSurvayPage(item, btn) {
      console.log(btn);
      if (btn.status == 3) {
        this.$iToast('订单结束14天后无法填写满意度调查');
        return;
      } else {
        this.$refs.projectGrade.showPopup(item, btn);
      }
    },
    scrollTop(filter) {
      this.loadType = 'top';
      this.pageIndex = 1;
      if (filter) {
        this.dataList = [];
      }
      this.StatByStatus(this.searchDate);
    },
    scrollBottom() {
      if (this.pageSize * this.pageIndex >= this.total) {
        this.loadType = 'none';
      } else {
        this.loadType = 'bottom';
        this.pageIndex += 1;
        this.QueryOnlineOrder();
      }
    },
    async Refresh() {
      let params = {
        proposalId: this.SmartStorage.get('proposalId')
      };
      this.services.Refresh(params).then(function(res) {
        if (res.success) {
          console.log(res);
        }
      });
    },
    refrashData(data) {
      this.StatByStatus(this.searchDate);
    },
    async StatByStatus(searchDate, isSearch) {
      // await this.Refresh();
      this.setParams(searchDate);
      this.searchDate = searchDate;
      if (isSearch) {
        this.pageIndex = 1;
      }
      let _this = this;
      this.services.StatByStatus(this.params).then(function(res) {
        if (res.success) {
          _this.orderTabs = _this.SmartStorage.get('viewOnly') ? res.content.filter(item => item.Txt != '未下单') : res.content;
          _this.tabBar = _this.tabBar.Txt ? _this.tabBar : _this.orderTabs[0];
          console.log(_this.pageIndex);
          _this.QueryOnlineOrder(true);
        }
      });
    },
    setParams(searchDate) {
      this.params = {
        Channel: 1,
        Filter: {
          SourceCode: this.filterDate.SourceCode
        }
      };
      if (this.SmartStorage.get('proposalId')) {
        this.params.Filter.ProposalId = this.SmartStorage.get('proposalId');
      }
      this.params.isAuthorized = this.$cookies.get('tenant') == 'msd' || this.SmartStorage.get('eventData').isFromMore ? true : false;
      if (this.filterDate && this.filterDate.Rule) {
        if (this.filterDate.Rule == '立即叫车') {
          // this.params.Filter.Rule = "立即叫车";
          this.params.Filter.$or = [
            {
              Rule: '立即叫车'
            },
            {
              TypeTxt: '立即叫车'
            }
          ];
        } else {
          this.params.Filter.$or = [
            {
              Rule: '预约叫车'
            },
            {
              Rule: '接机'
            },
            {
              Rule: '送机'
            },
            {
              Rule: '包天'
            },
            {
              TypeTxt: '预约叫车'
            }
          ];
        }
      }
      if (searchDate && searchDate.length > 0) {
        this.params.Filter.$or = searchDate;
      }
    },
    openStatusPop() {
      this.$refs.statusPop.openPop(this.tabBar);
    },
    btnClick(item, btn, index) {
      this.$iDelay(() => {
        // item.itsItemData = JSON.parse(item.ItemData);
        // item.itemData = item.ItemData;
        // item.itsItemData = JSON.parse(item.ItemData);
        item.itsExtOrderData = item.ExtOrderData && JSON.parse(item.ExtOrderData);
        item.itemId = item.ItemId;
        item.proposalId = item.ProposalId;
        item.extOrderId = item.ExtOrderId;
        item.isFromList = true;
        this.clickItem = item;
        console.log(this.clickItem);
        this[btn.Processor](item, btn, index);
      }, 200);
    },
    async editItem(item, btn, index) {
      if (!item && this.checkCanNewItem.forbidden) {
        this.checkCanNewItem.tooltip ? this.$iToast(this.checkCanNewItem.tooltip) : this.$iToast('暂时不能添加');
      } else {
        // item
        //   ? this.SmartStorage.set("editdataForm", item)
        //   : this.SmartStorage.remove("editdataForm");
        this.SmartStorage.set('editdataForm', item ? item : { isFromList: false });
        index && this.setListOn(index);
        this.$router.push('/platformEditOrder');
        this.SmartStorage.set('orderlistStatu', this.tabBar);
      }
    },
    viewOrder(item, index) {
      // item.itsItemData = JSON.parse(item.ItemData);
      item.itemId = item.ItemId;
      this.setListOn(index);
      if (this.isGoh5(item, false, ![0, -1, 5].includes(item.Status))) {
        this.SmartStorage.set('orderlistStatu', this.tabBar);
        item.itsItemData.itemId = item.ItemId;
        this.SmartStorage.set('orderDetail', item);
        localStorage && localStorage.setItem('_AMap_AMap.IndoorMap', '');
        this.$router.push({
          path: '/platformPriceCoupon'
          //   query: {
          //   params: item.Status==2?item.itsItemData:'',
          // },
        });
        //  item.Status==0&&this.SmartStorage.set("editdataForm", item)
        this.SmartStorage.remove('priceCouponData');
        item.Status == 2 ? this.SmartStorage.set('priceCouponData', item.itsItemData) : this.refreshHub.uninstall();
      }
    },
    changeTab(data, isStatus, filterDate) {
      this.$iDelay(() => {
        this.dropDown = !this.dropDown;
        this.tabBar = data;
        let _this = this;
        if (!isStatus) {
          this.filterDate = filterDate;
          this.StatByStatus(this.searchDate, true);
        } else {
          document.body.scrollTop = document.documentElement.scrollTop = 0;
          this.pageIndex = 1;
          this.QueryOnlineOrder(true);
        }
      }, 200);
    },
    //消息监听
    installSingler() {
      var that = this;
      that.refreshHub = new this.iSignalr({
        hubName: 'SmartHub',
        queryParams: 'group=' + this.SmartStorage.get('proposalId'),
        listeners: [
          {
            methodName: 'OrderStatusChanged',
            method: function(data) {
              console.log(data);
              if (data) {
                that.StatByStatus(that.searchDate);
              }
            }
          }
        ],
        host: process.env.VUE_APP_SIGNALER
      });
      that.refreshHub.install();
    },
    redirectRouteListeners() {
      this.SmartStorage.set('routeListeners', ['closeWebview']);
    },
    // 退出城市车队页面
    closeWebview() {
      const container = this.$cookies.get('container');
      switch (container) {
        case 'browser':
          this._browserBack();
          break;
        case 'h5':
          this._h5Back();
          break;
        case 'native':
        default:
          this.closeAppWithReload();
      }
    },
    //是否可以添加需求
    async checkItem(flag, item) {
      let param = {
        proposalId: this.SmartStorage.get('proposalId'),
        isOnline: true,
        sourceCode: ''
      };
      if (item) {
        param.itemId = item.itemId;
      }
      let _this = this;
      this.services
        .CheckCanNewItem(param)
        .then(function(res) {
          _this.checkCanNewItem = res.content;
          _this.SmartStorage.set('checkCanNewItem', res.content);
          flag && _this.editItem('');
          item && _this.checkCreateOrder(item);
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    checkCreateOrder(item) {
      if (this.checkCanNewItem && this.checkCanNewItem.forbidden) {
        this.checkCanNewItem.tooltip ? this.$iToast(this.checkCanNewItem.tooltip) : this.$iToast('暂时不能提交订单哦');
        this.isloding = true;
        return false;
      }
      this.$iDelay(() => {
        this.SmartStorage.set('orderDetail', item);
        this.SmartStorage.set('priceCouponData', item.itsItemData);
        this.$router.push({
          path: '/platformPriceCoupon',
          query: {
            params: item.itsItemData
          }
        });
      });
    },
    async QueryOnlineOrder(clear) {
      this.setParams(this.searchDate);
      console.log('this.params==', this.params);

      let params = this.params;
      params.pagerIndex = this.pageIndex;
      params.pagerSize = this.pageSize;
      params.Filter.Status = eval('(' + this.tabBar.CountExpr + ')').Status;
      params.Projection = {
        _id: 0,
        CreatedOn: 1,
        Addr_end: 1,
        Addr_start: 1,
        BudgetAmount: 1,
        CarLevel: 1,
        Channel: 1,
        Creator_DspName: 1,
        Creator_PhoneNumber: 1,
        Creator_UserName: 1,
        Departure_time: 1,
        ExtNum: 1,
        ExtOrderData: 1,
        ExtOrderId: 1,
        ExtOrderNum: 1,
        ExtOrderStatus: 1,
        ExtOrderStatusTxt: 1,
        IsDeleted: 1,
        ItemData: 1,
        ItemId: 1,
        Passenger_name: 1,
        Passenger_phone: 1,
        ProposalId: 1,
        Rule: 1,
        SettleAmount: 1,
        SourceCode: 1,
        SourceCodeTxt: 1,
        SourceIcon: 1,
        Status: 1,
        SupplierId: 1,
        TenantId: 1,
        TypeTxt: 1,
        UserId: 1,
        SortOrder: {
          $cond: {
            if: { $eq: ['$Status', -1] },
            then: 1000,
            else: '$Status'
          }
        }
      };
      //只有山德士需要批量确认用到taskId
      if (this.SmartStorage.get('tenant_code') == 'novartis') {
        params.withTaskId = true;
      }
      params.Sort = { CreatedOn: -1, SortOrder: 1 };
      let _this = this;
      this.services.Paging(params).then(function(res) {
        _this.isFirst = false;
        if (res.success) {
          if (clear == true) {
            _this.dataList = [];
          }
          let eventList = res.content.rows;
          let buttons = [
            {
              Processor: 'copyOrder',
              Txt: '复制订单'
            },
            {
              Processor: 'rebackOrder',
              Txt: '一键返程'
            }
          ];
          for (let i = 0; i < eventList.length; i++) {
            eventList[i].itsItemData = JSON.parse(eventList[i].ItemData);
            eventList[i].Passenger_name = _this.StringExchangeEmoji(eventList[i].Passenger_name);
            console.log(eventList[i].itsItemData.isUseH5);
            // if (
            //   (
            //     eventList[i].itsItemData.isUseH5) &&
            //   eventList[i].ItsButtons.length < 2&&eval("(" + _this.tabBar.CountExpr + ")").Status != 5
            // ) {
            //   eventList[i].ItsButtons = buttons.concat(eventList[i].ItsButtons);
            // }
            _this.dataList.push(eventList[i]);
          }
          _this.total = res.content.total;
          _this.loadType = '';
          _this.isloding = true;
          if (eval('(' + _this.tabBar.CountExpr + ')').Status == 5 && _this.dataList.length > 0 && !_this.SmartStorage.get('viewOnly') && _this.$cookies.get('tenant') == 'msd') {
            _this.GetExtraBtns();
          }
        }
      });
    },
    GetExtraBtns(flag) {
      let _this = this;
      let params = this.dataList.map(data => data.ItemId);
      this.services.GetExtraBtns(params).then(function(res) {
        if (res.success) {
          for (let i = 0; i < _this.dataList.length; i++) {
            if (params.some(item => item == res.content[i].itemId)) {
              let btn = {
                Txt: '满意度调查',
                Processor: 'goSurvayPage',
                status: res.content[i].status
              };
              _this.dataList[i].ItsButtons.push(btn);
            } else {
              console.log(res.content[i]);
            }
          }

          console.log(_this.dataList);
        }
      });
    },
    callCarPhone(item, btn, index) {
      this.showCallBox(item);
    },
    useCarRemark(item, btn, index) {
      this.isCarRemarkDialog = true;
    },
    showUseCarRemark(item, btn, index) {
      this.isCarRemarkDialog = true;
      this.showBtn = false;
    },
    async SaveUseCarRemark(actDescr) {
      this.isloding = false;
      let params = {
        ItemId: this.clickItem.itemId,
        UseCarRemark: actDescr
      };
      let _this = this;
      this.services.SaveUseCarRemark(params).then(function(res) {
        if (res.success) {
          _this.showBtn = true;
          _this.isCarRemarkDialog = false;
          _this.StatByStatus(_this.searchDate);
        }
        _this.isloding = true;
      });
    }
  }
};
</script>
<style lang="sass" scoped>
@import '@/assets/platform/index.scss'
</style>
